import React, { FC, useMemo, useRef } from 'react';
import Slider from 'react-slick';
import { Card } from '../Cards';
import { Text } from '../Text';
import { Wrapper, NavItem, Content } from './Slider.styled';
import { SliderStyles } from './SliderStyles';
import { default as ArrowRight } from 'public/arrow-next.svg';
import { StyledContentLink, ImgContainer } from '../Cards/Cards.styles';
import Link from 'next/link';
import { useDesktop, useTablet } from '@src/hooks';
import { ISlide } from '@src/models/ISlide';
import HTMLRender from '../HTMLRender';
import Image from 'next/image';

type Props = {
  slides?: ISlide[];
};

const NextArrow = props => {
  const { onClick } = props;
  return (
    <NavItem type={'next'} onClick={onClick}>
      <ArrowRight />
    </NavItem>
  );
};

const PrevArrow = props => {
  const { onClick } = props;
  return (
    <NavItem type={'prev'} onClick={onClick}>
      <ArrowRight />
    </NavItem>
  );
};

export const SliderContainer: FC<Props> = ({ slides }) => {
  const sliderRef = useRef();
  const containerRef = useRef<HTMLDivElement>();
  const isTabletDevice = useTablet();
  const isLargeDevice = useDesktop();
  const settings = useMemo(() => {
    return {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: isLargeDevice ? 3 : isTabletDevice ? 2 : 1,
      slidesToScroll: 1,
      prevArrow: <PrevArrow />,
      nextArrow: <NextArrow />,
    };
  }, [isTabletDevice, isLargeDevice]);

  if (!slides?.length) return null;

  return (
    <Wrapper ref={containerRef}>
      <SliderStyles />
      <Slider {...settings} ref={sliderRef}>
        {slides.map((slide, i) => (
          <Card key={i}>
            {slide.img?.src && (
              <Link href={slide.url} passHref>
                <StyledContentLink>
                  <ImgContainer>
                    <Image
                      loading="lazy"
                      src={slide.img.src}
                      width={387}
                      height={387}
                      alt={slide.img.alt}
                      objectFit={'cover'}
                    />
                    {/* <Img src={slide.img.src} alt={slide.img.alt} /> */}
                  </ImgContainer>
                </StyledContentLink>
              </Link>
            )}
            <Content>
              <h3>{slide.title}</h3>
              <Text size="small" color="light">
                <HTMLRender htmlContent={slide.content || ''} />
              </Text>
            </Content>
          </Card>
        ))}
      </Slider>
    </Wrapper>
  );
};
