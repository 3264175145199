import styled from '@emotion/styled';

export const Wrapper = styled.div(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden',
  marginBottom: theme.spacings.l,
}));

export const NavItem = styled.div<{ type: string }>(({ theme, type }) => ({
  background: theme.colors.main100,
  width: 48,
  height: 48,
  position: 'absolute',
  top: '30%',
  zIndex: 9,
  cursor: 'pointer',

  ...(type === 'prev' && {
    left: 0,

    svg: {
      transform: 'rotate(180deg)',
    },
  }),

  ...(type === 'next' && {
    right: 0,
  }),
}));

export const Img = styled.img({
  width: '100%',
  aspectRatio: '387 / 416',
  objectFit: 'cover',
});

export const Content = styled.div(({ theme }) => ({
  padding: theme.spacings.l,

  '*:last-child': {
    marginBottom: 0,
  },
}));
