import React, { useMemo } from 'react';
import get from 'lodash/get';
import { GridRow } from '@src/theme/Global.styled';
import { SliderContainer } from '@src/components/Slider';
import { IconLink } from '@src/components/Buttons';
import { ISlide } from '@src/models/ISlide';
import { IETHeros } from '@src/models/IETHeros';

interface HerosProps {
  block: IETHeros;
}

const Heros = (props: HerosProps) => {
  const { block } = props;
  const { slides } = useMemo(() => {
    const slides: ISlide[] = [];
    const fellows = get(block, 'fields.heroes', []) || [];
    fellows.forEach(fellow => {
      const fields = fellow.fields;
      const slide: ISlide = {
        title: get(fields, 'name_'),
        url: get(fields, 'link.url', '#') || '#',
        content: get(fields, 'htmlTitle'),
        img: {
          src: get(fields, 'image.fields.umbracoFile'),
          alt: get(fields, 'name_'),
        },
      };

      slides.push(slide);
    });
    return {
      slides,
    };
  }, [block]);

  return (
    <GridRow data-block-type={get(block, 'system.contentType')}>
      <h2>{get(block, 'fields.title')}</h2>
      <SliderContainer slides={slides} />
      {block.fields.links.map(link => (
        <IconLink
          key={link.name}
          type="page"
          href={get(link, 'url', '#') || '#'}
          target={get(link, 'target', '_self') || '_self'}
        >
          {get(link, 'name')}
        </IconLink>
      ))}
    </GridRow>
  );
};

export default Heros;
