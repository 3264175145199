import { Global } from '@emotion/react';

export const SliderStyles = () => (
  <Global
    styles={{
      '.slick-track': {
        display: 'flex',
      },
      '.slick-list': {
        margin: '0 -15px',
      },
      '.slick-slide': {
        padding: '0 15px',
        float: 'none',
        height: 'auto',

        '>div': {
          display: 'flex',
          height: '100%',
        },
      },
    }}
  />
);
